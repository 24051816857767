<template>
  <van-popup
    v-model="isShowPopup"
    :safe-area-inset-bottom="false"
    position="bottom"
    class="person-popup"
    :class="popupClass"
    get-container="body"
  >
    <div class="popup-title">
      <div class="btn cancel" @click="isShowPopup=false">取消</div>
      <div class="title">选择任务创建人</div>
      <div class="btn submit" @click="onSubmit">确定</div>
    </div>
    <div class="popup-body">
      <div class="search-box">
        <van-search
          v-model="keyword"
          placeholder="输入员工姓名进行搜索"
          @focus="popupClass = 'keyboard'"
          @blur="popupClass = ''"
        />
      </div>
      <div class="personal-list">
        <van-checkbox-group v-model="selected">
          <van-cell-group>
            <van-cell
              v-for="employee in cmpEmployeeList"
              :key="employee.id"
              value-class="value-class"
              clickable
              :value="employee.departmentName"
            >
              <template #title>
                <van-checkbox :name="employee.id">
                  {{ employee.name }}
                </van-checkbox>
              </template>
            </van-cell>
          </van-cell-group>
        </van-checkbox-group>
      </div>
    </div>
  </van-popup>
</template>

<script>
import { getEmployeeByOrgIdWithPage } from '@/api/modules/common'
import { mapGetters } from 'vuex'

export default {
  props: {
    selectIds: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShowPopup: false,
      keyword: '',
      selected: [],
      popupClass: '',
      employeeList: []
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),
    cmpEmployeeList() {
      return this.employeeList.filter(employee => this.keyword.length > 0 ? employee.name.includes(this.keyword) : true)
    }
  },

  created() {
    this.fetchEmployeeList()
  },

  methods: {
    onOpen() {
      this.isShowPopup = true
    },

    onSubmit() {
      const params = this.employeeList.filter(employee => this.selected.includes(employee.id)).map(employee => ({ userId: employee.id, name: employee.name }))
      this.$emit('on-change', params)
      this.isShowPopup = false
    },

    async fetchEmployeeList() {
      const res = await getEmployeeByOrgIdWithPage({
        orgIds: [this.userInfo.orgId],
        pageIndex: 1,
        pageSize: 999
      })
      if (res.code === 0) {
        this.employeeList = res.data.dataList.map(employee => {
          if (
            Array.isArray(employee.departments) &&
            employee.departments.length > 0
          ) {
            employee.departmentName = employee.departments[0].name
          }
          return employee
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.person-popup {
  &.keyboard {
    max-height: 280px;
    .popup-body {
      height: calc(280px - 54px);
    }
  }
  max-height: 480px;
  box-sizing: border-box;
  padding-top: 54px;
  .popup-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    line-height: 54px;
    box-sizing: border-box;
    padding: 0 16px;
    position: absolute;
    top: 0;
    background-color: #fff;
    z-index: 2;

    .title {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #333333;
    }
    .btn {
      font-size: 14px;
      cursor: pointer;
      &.cancel {
        color: #999999;
      }
      &.submit {
        font-family: PingFangSC-Medium;
        color: #287de1;
      }
    }
  }
  .popup-body {
    height: calc(480px - 54px);
    overflow-y: auto;
  }
  .value-class {
    color: #333333;
  }
}
</style>
