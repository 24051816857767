<template>
  <div class="task-create">
    <van-cell-group>
      <van-field
        v-model="taskModel.taskName"
        placeholder="请输入任务名称"
        maxlength="50"
      />
      <van-field
        v-model="taskModel.taskDetail"
        rows="4"
        type="textarea"
        placeholder="请填写任务内容，最多500字"
        class="detail-input"
        maxlength="500"
      />
      <van-cell
        v-if="taskModel.taskType === 2"
        title="设置周期"
        is-link
        :value="cmpFinishedTime"
        @click="onOpenPeriod"
      />
      <van-cell
        v-if="taskModel.taskType === 2"
        title="要求执行日期及时间"
        is-link
        :value="cmpEndTimeStr"
        @click="onOpenStartCalendar"
      />
      <van-cell
        v-if="taskModel.taskType === 1"
        title="任务截止日期及时间"
        is-link
        :value="cmpEndTimeStr"
        @click="onOpenEndCalendar"
      />
      <van-cell
        title="设置任务执行人"
        :value="
          taskModel.executors.length === 0 ? '任务执行人不设置时默认为自己' : ''
        "
        is-link
        class="custom-cell"
        @click="onOpenPerson"
      />
      <div class="tag-list">
        <van-tag
          v-for="e in taskModel.executors"
          :key="e.userId"
          plain
          type="primary"
          size="large"
          :closeable="!isEdit"
          @close="onRemoveEmployee(e.userId)"
        >
          {{ e.name }}
        </van-tag>
      </div>
    </van-cell-group>

    <div
      class="submit-btn"
      :class="{ disabled: cmpDisabledSubmit }"
      @click="onSubmit"
    >
      保存
    </div>

    <popup-period ref="refPeriod" @on-change="onChangePeriod" />

    <popup-datetime ref="refEndCalendar" @on-change="onChangeEndTime" />

    <popup-datetime ref="refStartCalendar" @on-change="onChangeStartTime" />

    <filter-person
      ref="refPerson"
      :select-ids="employeeIds"
      @on-change="onSelectEmployee"
    />
  </div>
</template>

<script>
import moment from 'moment'
import PopupPeriod from './components/popup-period.vue'
import FilterPerson from './components/filter-person.vue'
import PopupDatetime from './components/popup-datetime.vue'
import { mapGetters } from 'vuex'
import { addTask, updateTask, getTaskDetail } from '@/api/modules/task'
import { Toast } from 'vant'
import { WEEK_DAYS, MONTH_DAYS } from '@/utils'

export default {
  components: { PopupPeriod, PopupDatetime, FilterPerson },
  data() {
    return {
      taskModel: {
        taskName: '',
        taskDetail: '',
        taskType: 1,
        endTime: '',
        tenantId: global.APP_CONFIG.tenantId,
        executors: [],
        taskScheduleConfig: {},
        creator: {
          name: '',
          userId: ''
        }
      },
      periodText: '',
      isEdit: false,
      taskId: ''
    }
  },

  computed: {
    ...mapGetters({
      userInfo: 'userAuth/getUsersCurrentInfo'
    }),

    cmpDisabledSubmit() {
      if (this.taskModel.taskType === 1) {
        return (
          !this.taskModel.taskName ||
          !this.taskModel.taskDetail ||
          !this.taskModel.endTime
        )
      }

      return (
        !this.taskModel.taskName ||
        !this.taskModel.taskDetail ||
        Object.keys(this.taskModel.taskScheduleConfig).length === 0 ||
        !this.taskModel.endTime
      )
    },
    cmpMinDate() {
      return moment()
        .add(1, 'd')
        .toDate()
    },

    cmpMaxDate() {
      return moment()
        .add(1, 'y')
        .toDate()
    },

    cmpStartTimeStr() {
      return this.taskModel.startTime
        ? moment(this.taskModel.startTime).format('YYYY.MM.DD')
        : ''
    },

    cmpEndTimeStr() {
      return this.taskModel.endTime
        ? moment(this.taskModel.endTime).format('YYYY.MM.DD')
        : ''
    },

    employeeIds() {
      if (this.taskModel.executors.length > 0) {
        return this.taskModel.executors.map(e => e.userId)
      }
      return []
    },

    cmpFinishedTime() {
      let text = ''
      const taskScheduleConfig = this.taskModel.taskScheduleConfig
      if (Object.keys(taskScheduleConfig).length > 0) {
        const {
          jobType,
          dayOfWeeks,
          dayOfMonths,
          hour,
          minute,
          second
        } = taskScheduleConfig
        if (jobType === 2) {
          const index = WEEK_DAYS.findIndex(w => w.value === dayOfWeeks[0])
          text += '每周 ' + WEEK_DAYS[index].label
        }
        else {
          const index = MONTH_DAYS.findIndex(w => w.value === dayOfMonths[0])
          text += '每月 ' + MONTH_DAYS[index].label
        }
        text += ` ${hour < 10 ? '0' + hour : hour}:${
          minute < 10 ? '0' + minute : minute
        }:${second < 10 ? '0' + second : second}`
      }
      return text
    }
  },

  created() {
    const { type, taskId } = this.$route.params
    this.taskModel.taskType = type === 'once' ? 1 : 2
    const { name, userName } = this.$store.state.userAuth.usersCurrentInfo
    this.taskModel.creator = {
      userId: name,
      name: userName
    }
    if (taskId) {
      this.isEdit = true
      this.taskModel.taskId = taskId
      this.fetchTaskDetail()
    }
  },

  methods: {
    //  保存数据
    async onSubmit() {
      if (!this.taskModel.taskName) {
        return this.$toast('请填写任务名称')
      }

      if (!this.taskModel.taskDetail) {
        return this.$toast('请填写任务内容')
      }

      if (this.taskModel.taskType === 1) {
        if (!this.taskModel.endTime) {
          return this.$toast('任务截止日期及时间')
        }
      }
      else {
        if (!this.taskModel.endTime) {
          return this.$toast('要求执行日期及时间')
        }

        if (Object.keys(this.taskModel.taskScheduleConfig) === 0) {
          return this.$toast('请设置周期')
        }
      }

      const { endTime } = this.taskModel
      if (this.taskType === 2) {
        const {
          jobType,
          dayOfMonths,
          dayOfWeeks,
          hour,
          minute,
          second
        } = this.taskModel.taskScheduleConfig
        if (jobType === 2) {
          const week1 = this.getWeekDay(dayOfWeeks[0], hour, minute, second, 0)
          const week2 = this.getWeekDay(dayOfWeeks[0], hour, minute, second, 7)
          if (
            (Date.now() >= week1 || week1 >= endTime) &&
            (Date.now() >= week2 || week2 >= endTime)
          ) {
            Toast('没有可执行的任务,请重新选择！')
            return false
          }
        }
        else if (jobType === 3) {
          const month1 = this.getMonthDay(
            dayOfMonths[0],
            hour,
            minute,
            second,
            0
          )
          const month2 = this.getMonthDay(
            dayOfMonths[0],
            hour,
            minute,
            second,
            1
          )
          if (
            (Date.now() >= month1 || month1 >= endTime) &&
            (Date.now() >= month2 || month2 >= endTime)
          ) {
            Toast('没有可执行的任务,请重新选择！')
            return false
          }
        }
      }

      if (!this.taskModel.executors || this.taskModel.executors.length === 0) {
        this.taskModel.executors = [
          {
            userId: this.userInfo.name,
            name: this.userInfo.userName
          }
        ]
      }
      Toast.loading({
        message: '保存中...',
        forbidClick: true,
        overlay: true
      })
      const action = this.isEdit ? updateTask : addTask
      const res = await action(this.taskModel)
      if (res.code === 0) {
        Toast('保存成功')
        this.$router.push(`/task/list/${global.APP_CONFIG.tenantId}`)
      }
      else {
        Toast(res.msg)
      }
    },

    // 字符个数小于2拼接0
    timeToString(time) {
      if (time < 10) {
        return '0' + time
      }
      else {
        return time
      }
    },

    // 获取每月时间戳
    getMonthDay(cronValue, hour, minute, second, num = 0) {
      const curDate =
        moment()
          .add(num, 'month')
          .format('YYYY-MM') +
        '-' +
        this.timeToString(cronValue) +
        ' ' +
        this.timeToString(hour) +
        ':' +
        this.timeToString(minute) +
        ':' +
        this.timeToString(second)
      return new Date(curDate).getTime()
    },

    // 获取每周时间戳
    getWeekDay(cronValue, hour, minute, second, num = 0) {
      const curDate =
        moment()
          .startOf('week')
          .add(num + cronValue, 'd')
          .format('YYYY-MM-DD') +
        ' ' +
        this.timeToString(hour) +
        ':' +
        this.timeToString(minute) +
        ':' +
        this.timeToString(second)
      return new Date(curDate).getTime()
    },

    // 获取详情
    async fetchTaskDetail() {
      const params = {
        taskId: this.taskModel.taskId,
        tenantId: global.APP_CONFIG.tenantId
      }
      const res = await getTaskDetail(params)
      if (res.code === 0) {
        this.taskModel = { ...this.taskModel, ...res.data }
        this.taskModel.executors.push({
          userId: res.data.executorId,
          name: res.data.executorName
        })
      }
      else {
        Toast(res.msg)
      }
    },

    // 打开周期设置
    onOpenPeriod() {
      !this.isEdit && this.$refs.refPeriod.onOpen()
    },

    // 打开要求执行时间
    onOpenStartCalendar() {
      !this.isEdit && this.$refs.refStartCalendar.onOpen()
    },

    // 打开截止日期
    onOpenEndCalendar() {
      !this.isEdit && this.$refs.refEndCalendar.onOpen()
    },

    // 设置执行人
    onOpenPerson() {
      !this.isEdit && this.$refs.refPerson.onOpen()
    },

    // 删除执行人
    onRemoveEmployee(userId) {
      if (!this.isEdit) {
        const index = this.taskModel.executors.findIndex(
          u => u.userId === userId
        )
        if (index > -1) {
          this.taskModel.executors.splice(index, 1)
        }
      }
    },

    // 选择执行人
    onSelectEmployee(executors) {
      this.taskModel.executors = executors
    },

    // 设置执行的日期和时间
    onChangeStartTime(date) {
      this.taskModel.endTime = new Date(date).getTime()
    },

    // 设置截止的日期和时间
    onChangeEndTime(date) {
      this.taskModel.endTime = new Date(date).getTime()
    },

    // 修改周期触发
    onChangePeriod({ data, params }) {
      this.taskModel.taskScheduleConfig = params
      this.periodText = `${data[0]} ${data[1]} ${data[2]}:${data[3]}`
    }
  }
}
</script>

<style lang="less" scoped>
.task-create {
  box-sizing: border-box;

  /deep/ .van-cell {
    .van-cell__title {
      font-size: 14px;
      color: #999999;
    }
    .van-cell__value {
      color: #333;
    }
  }

  .detail-input {
    /deep/ .van-cell__value {
      background: #f5f5f5;
      border-radius: 0.10667rem;
      padding: 4px 10px;
    }
  }
  .custom-cell {
    /deep/ .van-cell__title {
      flex: auto;
      color: #999999;
    }
    .van-cell__value {
      flex: auto;
      color: #c1c1c1;
    }
  }

  .tag-list {
    box-sizing: border-box;
    padding: 16px;
    .van-tag {
      margin-right: 8px;
    }
  }

  .submit-btn {
    width: 314px;
    height: 40px;
    background: #287de1;
    border-radius: 20px;
    position: fixed;
    bottom: 16px;
    margin: 0 32px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color: #ffffff;
    &.disabled {
      background-color: #e2e2e2;
    }
  }
}
</style>
