<template>
  <van-popup
    v-model="isShowPopup"
    safe-area-inset-bottom
    position="bottom"
    class="person-popup"
  >
    <van-picker
      show-toolbar
      title="设置周期"
      :columns="columns"
      @cancel="isShowPopup = false"
      @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
const minutes = Array.from({ length: 60 }).map((v, k) => ({
  text: k < 10 ? `0${k}` : `${k}`
}))
const hours = Array.from({ length: 24 }).map((v, k) => ({
  text: k < 10 ? `0${k}` : `${k}`,
  children: minutes
}))
const months = Array.from({ length: 28 }).map((v, k) => ({
  text: `${k + 1}日`,
  children: hours
}))

const weeks = [
  '周一',
  '周二',
  '周三',
  '周四',
  '周五',
  '周六',
  '周日'
].map(k => ({ text: k, children: hours }))

export default {
  data() {
    return {
      isShowPopup: false,
      columns: []
    }
  },

  created() {
    this.columns = [
      {
        text: '每月',
        children: months
      },
      {
        text: '每周',
        children: weeks
      }
    ]
  },

  methods: {
    onConfirm(data) {
      const params = {
        dayOfMonths: [],
        dayOfWeeks: [],
        hour: parseInt(data[2]),
        jobType: data[0] === '每月' ? 3 : 2,
        minute: parseInt(data[3]),
        second: 0
      }
      if (data[0] === '每月') {
        params.dayOfMonths.push(parseInt(data[1]))
      }
      else {
        const index = weeks.findIndex(item => item.text === data[1])
        params.dayOfWeeks.push(index + 1)
      }
      this.$emit('on-change', { data, params })
      this.isShowPopup = false
    },
    // 打开 popup
    onOpen() {
      this.isShowPopup = true
    }
  }
}
</script>
