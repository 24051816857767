<template>
  <van-popup v-model="isShowPopup" get-container="body" position="bottom" class="datetime-popup">
    <van-datetime-picker
      v-model="currentTime"
      :min-date="minDate"
      :max-date="maxDate"
      type="datetime"
      :title="cmpTitle"
      :columns-order="['year', 'month', 'day', 'hour', 'minute']"
      @confirm="onConfirm"
    />
  </van-popup>
</template>

<script>
import moment from 'moment'
export default {
  data() {
    return {
      isShowPopup: false,
      columns: [],
      currentTime: moment().add(1, 'd').toDate(),
      minDate: new Date(),
      maxDate: moment().add(1, 'y').toDate()
    }
  },

  computed: {
    cmpTitle() {
      if (this.currentTime) {
        return moment(this.currentTime).format('YYYY-MM-DD HH:mm')
      }
      return '请选择'
    }
  },

  methods: {
    // 确定时间
    onConfirm() {
      this.$emit('on-change', this.currentTime)
      this.isShowPopup = false
    },

    onOpen() {
      this.isShowPopup = true
    }
  }
}
</script>

<style lang="less" scoped>
.datetime-popup {
  border-radius: 8px 8px 0 0;

  /deep/ .van-picker-column__item--selected {
    color: #3173cd;
  }

  .gap {
    position: absolute;
    left: 168px;
    top: 50px;
    color: #3173cd;
  }

  .popup-footer {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    border-top: 1px solid #f5f5f5;
    padding: 8px 0;
    .btn {
      height: 40px;
      flex: 0.5;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &.cancel {
        color: #999999;
        border-right: 1px solid #f5f5f5;
      }
      &.submit {
        color: #287de1;
      }
    }
  }
}
</style>
